//TODO: Write tests

const isBrowser = () => typeof window !== 'undefined';

export const getBrowserCookieValue = (name: string): string | null => {
  if (!isBrowser()) return null;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookie = parts.pop()?.split(';').shift();

    return cookie ? decodeURIComponent(cookie) : null;
  }

  return null;
};

export const setBrowserCookie = (
  name: string,
  value: string,
  expireDate: Date,
) => {
  if (!isBrowser()) return;
  document.cookie = `${name}=${value};expires=${expireDate.toUTCString()};path=/`;
};

export const deleteBrowserCookie = (name: string) => {
  if (!isBrowser()) return;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
